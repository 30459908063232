"use client";
import { SecondaryButton } from "@cakemembers/components-core";
import { BrandsSortOptions } from "@cakemembers/types";
import classNames from "classnames";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { PiCaretUpDownBold } from "react-icons/pi";

const brandsSortOptions: BrandsSortOptions[] = [
  "favorites",
  "new",
  "a-z",
  "z-a",
];

export function SelectBrandsSorting({ sort }: { sort?: BrandsSortOptions }) {
  const [show, setShow] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<typeof sort>(sort);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleSortChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const sortFmTarget = e.currentTarget.id as BrandsSortOptions;
    setSelectedSort(sortFmTarget);
    const params = new URLSearchParams(searchParams?.toString());
    params.set("sort", sortFmTarget);
    router.push(`/brands?${params.toString()}`);
    setShow(false);
  };

  useEffect(() => {
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    }

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [show, wrapperRef]);

  return (
    <div ref={wrapperRef} className="w-fit h-fit relative flex justify-center">
      <div onClick={() => setShow(!show)}>
        <SecondaryButton
          size="sm"
          className="flex items-center gap-2 h-8"
          padding="pl-3 pr-2"
        >
          {selectedSort || "Sort"} <PiCaretUpDownBold />
        </SecondaryButton>
      </div>
      <div
        hidden={!show}
        className="min-w-fit w-[168px] h-fit absolute top-[110%] right-0 z-10 transition-all"
      >
        <div className="rounded-sm bg-[#FEFEFD] shadow-[10px_30px_150px_rgba(46,38,92,0.25)]">
          <div className="flex flex-col divide-y">
            <button
              key="featured"
              className={classNames(
                !sort || selectedSort === "featured"
                  ? "text-[#292725]"
                  : "text-[#7D766E]",
                "px-4 py-2 uppercase text-start"
              )}
              id="featured"
              onClick={handleSortChange}
            >
              FEATURED
            </button>
            {brandsSortOptions.map((el) => (
              <button
                key={el}
                className={classNames(
                  el === selectedSort ? "text-[#292725]" : "text-[#7D766E]",
                  "px-4 py-2 uppercase text-start"
                )}
                id={el}
                onClick={handleSortChange}
              >
                {el}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
